<template>
  <div>
    <v-sheet dark v-if="event && discussionGroup">
      <logo class="hidden-lg-and-up" :logo="event.logoMobile" />

      <v-container
        id="discussion-group"
        class="px-0 py-0"
        :style="{ 'background-image': 'url(' + getBackground + ')' }"
        fluid
      >
        <v-row class="discussion-group" no-gutters>
          <v-col cols="12" lg="2">
            <logo
              v-for="logo in getMainLogos"
              :key="logo"
              class="hidden-md-and-down"
              :logo="logo"
            />
          </v-col>

          <v-col class="content" cols="12" lg="8">
            <h2>{{ discussionGroup.name }}</h2>

            <div class="room">
              <iframe
                style="width: 100%; border: none"
                class="room__iframe"
                :src="getWherebyUrl()"
                allow="camera; microphone; fullscreen; speaker; display-capture"
              ></iframe>
            </div>
          </v-col>

          <v-col cols="12" lg="2">
            <v-sheet dark class="chat" v-if="isUmanizeChat(discussionGroup)">
              <chat
                class="chat"
                :messages="moderatedMessages"
                :user="loggedInUser"
                @send-message="sendMessage"
              />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <sponsors :partners="getPartners" :showPlatinium="true" :includeOther="false" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Logo from '@/components/logo/Logo.vue';
import Chat from '@/components/chat/Chat.vue';
import Sponsors from '@/components/sponsors/Sponsors.vue';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  APP_DISCUSSION_GROUP_MODULE,
  CONNECT_TO_DISCUSSION_GROUP_SOCKET,
  DISCONNECT_FROM_DISCUSSION_GROUP_SOCKET,
  DISCUSSION_GROUP_SEND_CHATS_MESSAGE,
} from '@/stores/umanize-app/actions/discussion-group/discussion-group.actions';
import { GET_DISCUSSION_GROUP } from '@/stores/agnostic/actions/discussion-group/agnostic-discussion-group.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { whereByIframeSrcBuilder } from '@/helpers/whereby/whereby.helper';

export default {
  name: 'DiscussionGroup',
  components: {
    Sponsors,
    Logo,
    Chat,
  },
  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APP_DISCUSSION_GROUP_MODULE, ['discussionGroup', 'moderatedMessages']),
    discussionGroupLogos() {
      return (
        this.discussionGroup?.partners
          ?.filter((p) => p.type === 'principal')
          ?.map((p) => p.logoWeb) || []
      );
    },
    getMainLogos() {
      return [
        this.event.logoWeb,
        this.event.mainPartnerLogo,
        ...(this.event.options.discussionGroupsOptions?.leftPanelLogos || []),
        ...this.discussionGroupLogos,
      ].filter((e) => !!e);
    },
    getBackground() {
      if (this.discussionGroup.backgroundImage) {
        return this.discussionGroup.backgroundImage;
      }
      return this.event.options.discussionGroupsOptions?.backgroundImageUrl;
    },
    getPartners() {
      return [
        ...(this.event?.partners || []),
        ...(this.discussionGroup?.partners?.filter((p) => p.type === 'regular') || []),
      ];
    },
    isUmanizeChat() {
      return (discussionGroup) => discussionGroup.chatId && !discussionGroup.hasWherebyChat;
    },
  },
  methods: {
    ...mapActions(APP_DISCUSSION_GROUP_MODULE, [
      GET_DISCUSSION_GROUP,
      CONNECT_TO_DISCUSSION_GROUP_SOCKET,
      DISCUSSION_GROUP_SEND_CHATS_MESSAGE,
      DISCONNECT_FROM_DISCUSSION_GROUP_SOCKET,
    ]),
    sendMessage(message) {
      this[DISCUSSION_GROUP_SEND_CHATS_MESSAGE]({
        eventId: this.$route.params.eventId,
        discussionGroupId: this.$route.params.discussionGroupId,
        message,
      });
    },
    async initialize() {
      const { eventId, discussionGroupId } = this.$route.params;
      await this[GET_DISCUSSION_GROUP]({
        eventId,
        discussionGroupId,
      });
      await this[CONNECT_TO_DISCUSSION_GROUP_SOCKET]({
        discussionGroupId,
        userId: this.loggedInUser.id,
      });
    },
    getWherebyUrl() {
      return whereByIframeSrcBuilder(this.discussionGroup.wherebyUrl, {
        background: 'off',
        iframeSource: 'umanize',
        chat: this.discussionGroup.hasWherebyChat ? 'on' : 'off',
        people: 'off',
        screenShare: 'on',
        recording: 'on',
        displayName: `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`,
      });
    },
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this[DISCONNECT_FROM_DISCUSSION_GROUP_SOCKET](this.$route.params.discussionGroupId);
  },
  watch: {
    $route() {
      this.initialize();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

#discussion-group {
  min-height: calc(100vh - 6rem);
  background-repeat: no-repeat;
  background-position: center top;
}

.content {
  display: flex;
  flex-direction: column;

  min-height: 80vh;
}

.room {
  display: flex;
  justify-content: center;
  min-height: 80%;

  &__iframe {
    width: 90%;
  }
}

.chat {
  height: calc(100vh - 6rem);
}

.back {
  padding: 1rem 0;

  &__button {
    font-size: $large-font-size;
  }
}

@include breakpoint(small) {
  .room {
    &__iframe {
      width: 80%;
    }
  }
}

@include breakpoint(medium) {
  .room {
    &__iframe {
      width: 70%;
    }
  }
}
</style>
